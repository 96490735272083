/* eslint-disable array-callback-return,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/label-has-associated-control,react/button-has-type,react/no-access-state-in-setstate,jsx-a11y/no-noninteractive-element-interactions,no-shadow,react/jsx-no-target-blank,react/no-unused-state,no-unused-expressions */
import React, { Component } from "react";
import withContext from "../../WithContextFile";
import "./AdminPanel.scss";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CropFreeIcon from "@material-ui/icons/CropFree";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Page from "@material-ui/icons/MenuBook";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
	AddCircleOutlined,
	AssignmentInd,
	CreateOutlined,
	DeleteOutlined,
	ExpandLess,
	ExpandMore,
	FilterFrames,
	VisibilityOutlined,
} from "@material-ui/icons";
import { Spinner } from "react-activity";
import { BigPlayButton, Player } from "video-react";
import Collapse from "@material-ui/core/Collapse";
import moment from "moment";
import ModifyDeceasedPage from "./ModifyDeceasedPage";
import AreYouSureGeneral from "../FormComponents/AreYouSureModalGeneral";
import AreYouSureModalGeneral from "../FormComponents/AreYouSureModalGeneral";
import SuccessToast from "./helperComponent/SuccessToast";
import DoYouWanToDelete from "./DoYouWanToDelete";
import ModifyObituaryData from "./ModifyObituaryData";
import dedics2 from "../../assets/Stab/Dedic request";
import Partner from "../Partner/Partner";
import PartnerPagePanel from "../Partner-page/Partner-page-panel";
import azienda from "../../assets/Stab/Azienda";
import LampadePopup from "./ClientPanelPages/LampadePopup";
import AbbonatoPopup from "./LampadePanelPages/AbbonatoPopup";
import AddPagamentoPopup from "./LampadePanelPages/AddPagamentoPopup";
import requests, { baseURL } from "../../Services/HttpServices";
import ResetPasswordForm from "../Auth/Password-reset/ResetPasswordForm";
import ContactPreferenceForm from "../FormComponents/ContactPreferencesForm";
import ModifyAccount from "../Auth/ModifyAccount/ModifyAccount";
import AddLampadaPoster from "./LampadePanelPages/AddLampadaPoster";
import AddAbbonatoPoster from "./LampadePanelPages/AddAbbonatoPoster";
import LampadePanelTableWrapper, { createLampadeDataPartnerTable } from "./LampadePanelPages/LampadePanelTableWrapper";
import FeedbackModal from "../FormComponents/FeedbackModal";
import { mergeObjs } from "../../Services/RandomUtils";

let notModal = false;

let newRows     = [];
const subtitles = [
	" Lampade Votive",
	"Gestione pagina partner",
	"Gestione account"
];
let lampade    = [];
let user;
const pathNames = [
	"lampade",
	"partner-page",
	"account",
	"aggiungi-lampada",
	"aggiungi-abbonato",
];
let pageError   = true;
let radioValue;

class AdminPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type         : 0,
			modal        : false,
			order        : "asc",
			orderBy      : "calories",
			showLampade  : false,
			radioValue   : "",
			aziendaData  : null,
			gettingData  : false,
		};
		let radioContact;
		try {
			radioContact = this.state.loggedUser?.preferred_contact?.type;
		} catch (e) {
			console.log(e);
			radioContact = "";
		}

		this.setState({ radioValue: radioContact });
	}

	componentDidMount() {
		try {
			if (JSON.parse(sessionStorage.getItem("User"))) {
				user = JSON.parse(sessionStorage.getItem("User"))?.userdata;
				user
				&& requests("get", "getPartnerPage", user.partnerid)
					.then((result) => {
						if (result.data.response === "success") {
							this.setState({ aziendaData: result.data.data });
						}
					});
				this.setState({ gettingData: true });
				requests("get", "lampadeGestoreData", user.user_id)
					.then(async (data) => {
						if (`${data.data.result}` === "success") {
							lampade = [];
							await Promise.all(data.data.lampadeData.map(async (e, i) => {
								let lampadaData = data.data.lampadeData[i];

								//SCARICA DATI DEFUNTO
								let requestDefunto = await requests("get", "deceasedData", lampadaData.idDefunto);
								if (`${requestDefunto.data.result}` === "success") {
									if (requestDefunto.data.deceasedData.name !== "" && requestDefunto.data.deceasedData.showPage == "1") {
										lampadaData.defunto = requestDefunto.data.deceasedData;
									}
								}

								//SCARICA DATI ABBONATO
								if(lampadaData.idAbbonato != null && parseInt(lampadaData.idAbbonato, 10) != 0) {
									const bodydata = {};
  									bodydata["idAbbonato"] = parseInt(lampadaData.idAbbonato, 10);
									let requestAbbonato = await requests("post", "lampadaAbbonato", bodydata);
									if (requestAbbonato?.result === "success") {
										lampadaData.abbonato = requestAbbonato.lampadaAbbonato;
									}
								}

								lampade.push(lampadaData);
							}));
							this.setState({ gettingData: false });
							this.lampadeDataTable();
						}
					});
			} else {
				console.log("errore");
			}
		} catch (e) {
			console.log("errore");
		}
	}

	showSuccessTost(sold) {
		this.setState({ successToastOpacity: 100 });
		setTimeout(() => {
			const interval = setInterval(() => {
				this.setState({
					successToastOpacity: this.state.successToastOpacity - 3,
				});
				if (this.state.successToastOpacity <= 0) {
					clearInterval(interval);
				}
			}, 25);
		}, 500);
	}

	render() {
		let user;
		try {
			 user = sessionStorage.getItem("User") && JSON.parse(sessionStorage.getItem("User")).userdata;
		} catch (e) {}

		if (!user) {
			window.location.href = "/home";
			// pageError = false
		} else if (user.permissions.platformAccess == 1) {
			window.location.href = "/admin-panel";
			pageError            = true;
		} else if (user.permissions.platformAccess == 0) {
			window.location.href = "/client-panel";
			pageError            = true;
		} else if (window.location.hash) {
			pageError       = false;
			const pathIndex = pathNames.indexOf(window.location.hash.slice(1, window.location.hash.length));
			if (pathIndex === -1) {
				radioValue = user.preferred_contact.type;
				this.setState({ radioValue });
				window.history.pushState("object or string", "Title", "/lampade-panel#lampade");
				this.setState({ type: 0 });
				this.lampadeDataTable();
			} else if (this.state.type !== pathIndex) {
				radioValue = user.preferred_contact.type;
				this.setState({ radioValue });
				this.setState({ type: pathIndex });
				pathIndex === 0 && this.lampadeDataTable();
				pathIndex === 3 && this.lampadeDataTable();
			}
		} else {
			window.history.pushState("object or string", "Title", "/lampade-panel#lampade");
			radioValue = user.preferred_contact.type;
			this.setState({ radioValue });
			this.lampadeDataTable();
			pageError = false;
		}
		if (pageError) return <></>;
		return (
			<>
				{" "}
				{this.state.showLampadePopup && (
					<LampadePopup
						lampadaData = {this.state.lampadaDataState}
						lampadeCollegate = {this.state.lampadeCollegateState}
						nomeDefunto = {this.state.showLampadePopupNome}
						close={() => this.setState({ showLampadePopup: null })}
					/>
				)}
				{this.state.showAbbonatoPopup && (
					<AbbonatoPopup
						abbonatoData = {this.state.abbonatoDataState}
						serialeLampada = {this.state.serialeLampadaState}
						lampadaTermine = {this.state.lampadaTermineState}
						storicoPagamenti = {this.state.storicoPagamentiState}
						addPagamento={(idAbbonato, serialeLampada, lampadaTermine) => this.setState({ idAbbonatoState: idAbbonato, serialeLampadaState: serialeLampada, lampadaTermineState: lampadaTermine, showAddPagamentoPopup: true })}
						close={() => this.setState({ showAbbonatoPopup: null })}
					/>
				)}
				{this.state.showAddPagamentoPopup && (
					<AddPagamentoPopup
						idAbbonato = {this.state.idAbbonatoState}
						serialeLampada = {this.state.serialeLampadaState}
						lampadaTermine = {this.state.lampadaTermineState}
						setState={() => this.setState({ showAddPagamentoPopup: null })}
						close={() => this.setState({ showAddPagamentoPopup: null })}
					/>
				)}
				{this.state.choiceModal
				&& (
					<AreYouSureModalGeneral
						closeHandler={() => this.setState({ choiceModal: false })}
						text={this.state.choiceModalText}
						accept={() => {
							this.setState({ choiceModal: false });
							this.state.choiceModalFunc();
						}}
					/>
				)}
				{this.state.feedbackModal && (
					<FeedbackModal
						result={this.state.feedbackModalResult}
						close={() => this.setState({ feedbackModal: false })}
						label={this.state.feedbackModalLabel}
					/>
				)}
				{this.state.modalLoading && (
					<div className="modal-bg">
						<div
							className="flex flex-column"
							style={{
								alignItems     : "center",
								justifyContent : "center",
							}}
						>
							<CircularProgress color="primary" />
							{" "}
							<Typography className="text-center" style={{ color: "white" }}>
								{this.state.loadingLabel}
							</Typography>
						</div>
					</div>
				)}
				{this.state.openModifyDeceased && (
					<ModifyDeceasedPage
						partner
						data={this.state.modifyData}
						closeHandler={() => {
							this.setState({ openModifyDeceased: false });
						}}
					/>
				)}
				<div className="bg-white dashboard">
					<div id="second-in">
						<SuccessToast
							label={this.state.toastLabel}
							successToastOpacity={this.state.successToastOpacity}
						/>
						<div id="upper">
							<div id="card-row">
								<div
									id="card0"
									onClick={() => {
										document.getElementById("card-row")
											.scrollIntoView({ behavior: "smooth" });
										this.lampadeDataTable();
										window.history.pushState(
											"object or string",
											"Title",
											"/lampade-panel#lampade",
										);
										this.setState({ showLampade: true });
										this.setState({ type: 0 });
									}}
									className="ml-0 card-fix"
								>
									<div>
										<label id="title-card">Gestione Lampade Votive</label>
										<label>{lampade.length || 0}</label>
									</div>
									<div id="icon">
										<AssignmentInd style={{ fontSize: 19 }} />
									</div>
								</div>
								<div
									id="card"
									className="card-fix"
									onClick={() => {
										document.getElementById("card-row")
											.scrollIntoView({ behavior: "smooth" });
										window.history.pushState(
											"object or string",
											"Title",
											"/lampade-panel#partner-page",
										);
										this.setState({ type: 2 });
									}}
								>
									<div id="page-detail">
										<label style={{
											color    : "#2d2b2b",
											fontSize : 20,
										}}
										>
											Gestione pagina partner
										</label>
										<div id="icon" style={{ backgroundColor: "lightsalmon" }}>
											<Page style={{ fontSize: 19 }} />
										</div>
									</div>
								</div>
								<div
									id="card"
									className="card-fix"
									onClick={() => {
										document.getElementById("card-row")
											.scrollIntoView({ behavior: "smooth" });
										window.history.pushState("object or string", "Title", "/lampade-panel#account");
										this.setState({ type: 4 });
									}}
								>
									<div id="page-detail" className="place-fix">
										<label style={{
											color    : "#2d2b2b",
											fontSize : 22,
										}}
										>
											Gestione account
										</label>

										<div id="icon" style={{ backgroundColor: "skyblue" }}>
											<AccountCircleIcon style={{ fontSize: 19 }} />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="content" style={{ position: "relative" }}>
							{this.state.type === 0 && (
								<button
									color="primary"
									className="extra-button-main-style left search-button"
									style={{ minWidth: "fit-content" }}
									onClick={() => {
										window.history.pushState(
											"object or string",
											"Title",
											"/lampade-panel#aggiungi-abbonato",
										);
										this.setState({ type: 4 });
									}}
								>
									<AddCircleOutlined className="extra-button-icon-style" />
									Aggiungi Abbonato
								</button>
							)}
							{this.state.type === 0 && (
								<button
									color="primary"
									style={{ minWidth: 250 }}
									className="search-button panel-button-resize partnerpanel-button toRight"
									onClick={() => {
										window.history.pushState(
											"object or string",
											"Title",
											"/lampade-panel#aggiungi-lampada",
										);
										this.setState({ type: 3 });
									}}
								>
									<AddCircleOutlined className="extra-button-icon-style" />
									Aggiungi Lampada
								</button>
							)}
							<div className="panel-title-div" style={{ marginBottom: this.state.type !== 0 && 33 }}>
								<label
									className={`admin-content-subtitle ${
										`${this.state.type}` === "0" || `${this.state.type}` === "6"
											? " partner-title-150"
											: `${this.state.type}` === "5" && " partner-title-80"
									}`}
									style={{
										marginBottom : !this.state.type === 0 && 33,
										margin       : this.state.type === 0 && "10px auto",
										display      : "flex",
									}}
								>
									{this.state.type === 0 && "Gestione "}
									{this.state.type === 6 && "Gestione Lampade Votive"}
									{this.state.type === 0 && <p>&nbsp;</p>}
									<p
										onClick={() => {
											this.state.showLampade && this.setState({ showLampade: false });
										}}
									>
										{this.state.type !== 0
											? subtitles[this.state.type]
											: "Lampade Votive"}
									</p>
								</label>
							</div>
							{this.state.type === 2 && (
								<div
									id="collapsable-container"
									style={{
										display       : "flex",
										flexDirection : "column",
									}}
								>
									<div
										onClick={() => {
											this.setState({
												collapse  : !this.state.collapse,
												collapse1 : false,
												collapse2 : false,
											});
										}}
										className="collapsable-list"
									>
										<div
											style={{
												display       : "flex",
												flexDirection : "row",
												margin        : "auto",
											}}
										>
											<label style={{
												margin      : "auto",
												paddingLeft : 24,
											}}
											>
												{" "}
												Cambia password
											</label>
											<div>{this.state.collapse ? <ExpandLess /> : <ExpandMore />}</div>
										</div>
									</div>

									<Collapse in={this.state.collapse} timeout="auto" unmountOnExit>
										<div style={{
											display        : "flex",
											justifyContent : "center",
										}}
										>
											<ResetPasswordForm />
										</div>
									</Collapse>

									<div
										onClick={() => {
											this.setState({
												collapse1 : !this.state.collapse1,
												collapse2 : false,
												collapse  : false,
											});
										}}
										className="collapsable-list"
									>
										<div
											style={{
												display       : "flex",
												flexDirection : "row",
												margin        : "auto",
											}}
										>
											<label style={{
												margin      : "auto",
												paddingLeft : 24,
											}}
											>
												{" "}
												Cambia preferenze contatto
											</label>
											<div>{this.state.collapse1 ? <ExpandLess /> : <ExpandMore />}</div>
										</div>
									</div>

									<Collapse in={this.state.collapse1} timeout="auto" unmountOnExit>
										<div id="preferences-panel">
											<div id="toCenterFix">
												<ContactPreferenceForm
													logged
													setting
													email={user.email}
													phone={user.telephone}
													handler={(event, value) => {
														this.setState({ radioValue: value });
													}}
													value={this.state.radioValue}
													error={this.state.radioError}
													label="Cambia la tua preferenza contatto"
												/>
											</div>
										</div>
										<div
											className="field is-clearfix"
											style={{
												marginLeft   : "auto",
												marginRight  : "auto",
												marginBottom : 20,
											}}
										/>
									</Collapse>

									<div
										onClick={() => {
											this.setState({
												collapse2 : !this.state.collapse2,
												collapse1 : false,
												collapse  : false,
											});
										}}
										className="collapsable-list"
									>
										<div
											style={{
												display       : "flex",
												flexDirection : "row",
												margin        : "auto",
											}}
										>
											<label style={{
												margin      : "auto",
												paddingLeft : 24,
											}}
											>
												{" "}
												Cambia dati account
											</label>
											<div>{this.state.collapse2 ? <ExpandLess /> : <ExpandMore />}</div>
										</div>
									</div>
									<Collapse in={this.state.collapse2} timeout="auto" unmountOnExit>
										<div style={{
											display        : "flex",
											justifyContent : "center",
										}}
										>
											<ModifyAccount partner azienda={this.state.aziendaData} />
										</div>
									</Collapse>
								</div>
							)}
							{this.state.gettingData && (
								<div style={{ margin: "auto" }}>
									<Spinner />
								</div>
							)}
							{this.state.type === 1 && (
								<div>
									<PartnerPagePanel data={azienda} partnerID={user ? user.partnerid : 8} />
								</div>
							)}
							{this.state.type === 3 && <AddLampadaPoster />}
							{this.state.type === 4 && <AddAbbonatoPoster />}
							{this.state.type === 0 && this.newTable(this.state.type === -0)}
						</div>
					</div>
					{this.state.modal && this.modal()}
				</div>
			</>
		);
	}

	newTable(table2) {
		return (
			<LampadePanelTableWrapper
				orderID={this.state.newOrderID}
				filter={{}}
				table2={table2}
				history={this.props.history}
				deleteAllOperation={() => {
					alert("delete all");
				}}
				setChecked={(data) => {
					this.setState({ checked: data });
				}}
				setFilter={(data) => {
					this.setState({ filter: data });
				}}
				setNumber={() => {
				}}
				setAllNumber={() => {
				}}
				type={this.state.type}
				data={newRows}
				actionData={(
					<div>
						<CreateOutlined fontSize="small" className="dash-icon" />
						<VisibilityOutlined fontSize="small" className="dash-icon" />
						<DeleteOutlined fontSize="small" className="dash-icon" />
					</div>
				)}
			/>
		);
	}

	modal() {
		return (
			<div
				onClick={() => {
					if (!notModal) {
						this.setState({ modal: false });
					} else {
						notModal = false;
					}
				}}
				className="admin-panel-modal"
			>
				<CloseIcon id="crossIcon" style={{ zIndex: 3000 }} />
				<div className="flex">
					<div
						style={{ marginBottom: 40 }}
						onClick={() => {
							notModal = true;
						}}
					>
						<Partner data={azienda} type="modal" />
					</div>
				</div>
			</div>
		);
	}

	lampadeDataTable() {
		newRows   = [];
		lampade.map((e, i) => {
			newRows.push(createLampadeDataPartnerTable(e, (data) => this.setState(data)));
		});
		this.setState({ loaded: true });
	}

	createSortHandler = (property) => (event) => {
		const isAsc = this.state.orderBy === property && this.state.order === "asc";
		this.setState({ order: isAsc ? "desc" : "asc" });
		this.setState({ orderBy: property });
	};
}

export default withContext(AdminPanel);
