/* eslint-disable no-unused-expressions,react/button-has-type */
import React, { useEffect, useState } from "react";
import "../ModifyAccount/ModifyAccount.scss";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AddCircleOutlined, InfoRounded } from "@material-ui/icons";
import { Checkbox, Tooltip } from "@material-ui/core";
import AddressService from "../../FormComponents/AddressComponent";
import ModifyAccountNotificationAddresses from "./ModifyAccountNotificationAddresses";
import ShowInfo from "./NotificationInfo";
import NotificationTiming from "../Registrazione/NotificationTiming";
import requests from "../../../Services/HttpServices";
import SuccessModification from "./SuccessModal";
import FeedbackModal from "../../FormComponents/FeedbackModal";

export default function ModifyAccountNotification(props) {
	const [switchStatus, setSwitchStatus]             = useState(false);
	const [number, setNumber]                         = useState(1);
	const [arrNumber, setArrNumber]                   = useState(new Array(number).fill(0));
	const [toSend, setToSend]                         = useState([]);
	const [change, setChange]                         = useState(false);
	const [minus, setMinus]                           = useState(false);
	const [showInfo, setShowInfo]                     = useState(false);
	const [sended, setSended]                         = useState(false);
	const [sessionData, setSessionData]               = useState(null);
	const [notificationTiming, setNotificationTiming] = useState(props.timeData);
	const [successModal, setSuccesModal]              = useState(null);
	const [modalResult, setModalResult]               = useState(null);
	const [modalText, setModalText]                   = useState(null);
	const [timeData, setTimeData]                     = useState(null);
	const [deleting, setDeleting]                     = useState(false);

	if (props.sessionData && sessionData !== props.sessionData && !deleting) {
		setSessionData(props.sessionData);
		setNumber(props.sessionData.length);
		setArrNumber(new Array(props.sessionData.length).fill(0));
		const toSendTemp = toSend;
		props.sessionData.length > 0
		&& props.sessionData.forEach((e, i) => {
			let temp = {};
			if (e.location) {
				temp = { location: e.location };
			} else if (e.province) {
				temp = { province: e.province };
			} else if (e.region) temp = { region: e.region };

			toSendTemp.push(temp);
			setToSend(toSendTemp);
		});
		if (!props.setting || props.sessionData.length > 0) {
			setSwitchStatus(false);
		}
	}

	useEffect(() => {
		setTimeData(props.timeData || "realtime");
		setNotificationTiming(props.timeData || "realtime");
	}, [props.timeData]);

	useEffect(() => {
		if (!props.setting) setSwitchStatus(props.registration);
	}, [props.registration, props.setting]);
	useEffect(() => {
		console.log(props.sessionData);
		if (props.sessionData) {
			setSessionData(props.sessionData);
			setNumber(props.sessionData.length);
			setArrNumber(new Array(props.sessionData.length).fill(0));
			const toSendTemp = [];
			props.sessionData.length > 0
			&& props.sessionData.forEach((e, i) => {
				let temp = {};
				if (e.location) {
					temp = { location: e.location };
				} else if (e.province) {
					temp = { province: e.province };
				} else if (e.region) temp = { region: e.region };

				toSendTemp.push(temp);
				setToSend(toSendTemp);
			});
			if (!props.setting || props.sessionData.length > 0) {
				setSwitchStatus(false);
			}
		}
	}, [props.reset]);
	return (
		<div className="notification-main-container">
			{/* {successModal && <SuccessModification/>} */}
			{successModal && (
				<FeedbackModal
					result={modalResult}
					close={() => {
						setSuccesModal(false);
						window.location.reload();
						// this.setState({name: '',number:'',selectedValue:''})
					}}
					label={modalText}
				/>
			)}
			<div id="title-container">
				<FormControlLabel
					control={(
						<Checkbox
							checked={switchStatus}
							onChange={(event, checked) => {
								if (switchStatus) {
									setDeleting(true);
									setNumber(1);
									setArrNumber(new Array(1).fill(0));
									setToSend([]);
									setSessionData([]);
									setTimeData("realtime");
									setNotificationTiming("realtime");
									setChange(!change);
								}
								setSwitchStatus(!switchStatus);
								props.onChangeFlag(!switchStatus);
							}}
							name="registration-flag"
							color="primary"
							inputProps={{ "aria-label": "secondary checkbox" }}
						/>
					)}
					label="Voglio ricevere le notifiche"
				/>
				<InfoRounded
					style={{
						color  : "#fa0",
						cursor : "pointer",
					}}
					onClick={() => setShowInfo(true)}
				/>
			</div>
			{showInfo && (
				<ShowInfo
					handleClose={() => {
						setShowInfo(false);
					}}
				/>
			)}
			{switchStatus && (
				<div
					className="notification-container"
					style={{
						height      : "auto",
						boxShadow   : props.type === "registration" && "none",
						paddingLeft : props.centered && 0,
					}}
				>
					<div
						className="buy-form-paragraph"
						style={{
							margin       : "0 auto",
							textAlign    : "center",
							marginBottom : 10,
						}}
					>
						Scegli le localita' d'interesse
					</div>
					{arrNumber.map((e, i) => (
						<ModifyAccountNotificationAddresses
							centered={props.centered}
							sessionData={sessionData && sessionData[i]}
							submit={props.type === "registration" ? props.submit : sended}
							number={number}
							index={i}
							handleChange={(data) => {
								const temp = toSend;
								temp[i]    = data;
								setToSend(temp);
								setChange(!change);
								props.sendArray(temp);
							}}
							handleRemove={() => {
								setNumber(number - 1);
								setArrNumber(new Array(number - 1).fill(0));
								const temp = toSend;
								temp.pop();
								setMinus(true);
								setToSend(temp);
								setChange(!change);
							}}
						/>
					))}
					{(toSend.length >= arrNumber.length || minus) && (
						<Tooltip title={"Aggiungi zona d'interesse"}>
							<AddCircleOutlined
								style={{
									cursor    : "pointer",
									color     : "#fa0",
									marginTop : 20,
									// display: !toSend[arrNumber.length - 1] && 'none'
								}}
								onClick={() => {
									setNumber(number + 1);
									setArrNumber(new Array(number + 1).fill(0));
									setChange(!change);
									setMinus(false);
								}}
							/>
						</Tooltip>
					)}
					&nbsp;
					{toSend.length > 0 && (
						<NotificationTiming
							sessionData={timeData}
							onChange={(data) => {
								setNotificationTiming(data);
							}}
						/>
					)}
					{props.type !== "registration" && (
						<button
							onClick={() => {
								setSended(true);
								if (
									toSend[0].region
									|| toSend[0].province
									|| toSend[0].location
									|| toSend[0].city
									|| toSend[0].cap
								) {
									const bodyData = {
										activeNotification    : true,
										notificationFrequency : notificationTiming,
										notificationList      : JSON.stringify(toSend),
									};
									requests("post", "modifyContactPreferece", bodyData)
										.then((data) => {
											props.setContactData(notificationTiming);
											setSuccesModal(true);

											if (data.response === "success") {
												setModalResult("Successo");
												setModalText("Le modifiche sono state apportate con successo");
											}
										});
								}
							}}
						>
							SALVA
						</button>
					)}
				</div>
			)}
		</div>
	);
}
